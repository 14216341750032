import Card from "./card/card"
import styles from "./why-techcore-block.module.css"
import { ReactComponent as ArrowIcon } from "../../../shared/img/arrow-icon.svg"
import { ReactComponent as SearchIcon } from "../../../shared/img/search-icon.svg"
import { ReactComponent as DocumentIcon } from "../../../shared/img/document-icon.svg"
import { ReactComponent as ManageIcon } from "../../../shared/img/manage-icon.svg"


const WhyTechcoreBlock = () => {
    return (
        <div className={styles.why_techcore_block}>
            <div className="container">
                <h3 className={styles.title}>Why Techcore</h3>

                <p className={styles.info}>We have people, platform and process to help your company succeed.</p>

                <div className={styles.cards}>
                    <Card title="Define needs">
                        <ArrowIcon />
                    </Card>

                    <Card title="Review matches">
                        <SearchIcon />
                    </Card>

                    <Card title="Interview & Hire">
                        <DocumentIcon />
                    </Card>

                    <Card title="Manage">
                        <ManageIcon />
                    </Card>
                </div>

                <div className={styles.button_block}>
                    <a
                        href="https://outlook.office365.com/book/meet@techcore-group.com/"
                        target="_blank"
                        rel="noreferrer"
                        className={styles.button}
                    >
                        schedule a call
                    </a>
                </div>
            </div>
        </div>
    )
}

export default WhyTechcoreBlock