import {Link, useNavigate} from "react-router-dom"
import styles from "./footer.module.css"
import NavLinkScroll from "../nav-link-scroll/nav-link-scroll";
import { Button, Modal } from "antd";
import { useState } from "react";
import ContactUs from "../contact-us/contact-us";
import { ReactComponent as LinkedinLogo } from "../../../shared/img/linkedin-logo.svg"

const Footer = ({ navigateTrigger }) => {
	const navigate = useNavigate()

	const [isContactUsModalVisible, setIsContactUsModalVisible] = useState(false)

	const goToAnotherPageAndScroll = async (page, block) => {
		navigateTrigger(prev => prev + 1)
		navigate(page, { state: { blockName: block } })
	}

	return (
		<>
			<div className={styles.footer_block}>
				<div className="container">
					<div className={styles.content}>
						<div className={styles.info_blocks}>
							<div className={styles.logo_block}>
								<img src="/img/logo.svg" alt="logo-icon" />

								<div className={styles.contact_us_wrapper}>
									<div className={styles.contact_us_button}>
										<Button
											onClick={() => { setIsContactUsModalVisible(true) }}
											className={styles.button}
										>
											CONTACT US
										</Button>
									</div>

									<address className={styles.contact_us_address_block}>
										<p className={styles.contact_us_address} >Poland <a href="tel:+48571430801">(+48) 571430801</a></p>
										<p className={styles.contact_us_address} >Warsaw, st. Nowogrodzka 31/413</p>
									</address>

									<p className={styles.follow_us}>FOLLOW US</p>

									<a
										href="https://www.linkedin.com/company/techcoregroup"
										target="_blank"
										rel="noreferrer"
										className={styles.social_media_button}
									>
										<LinkedinLogo />
									</a>
								</div>
							</div>

							<div className={styles.links_block}>
								<div className={styles.links}>
									<div className={styles.link_button}>
										<Button onClick={() => { goToAnotherPageAndScroll("/", "what_we_do") }}>
											WHAT WE DO
										</Button>
									</div>

									<div className={styles.link_button}>
										<Button onClick={() => { goToAnotherPageAndScroll("/", "technologies_block") }}>
											TECHNOLOGIES
										</Button>
									</div>
								</div>
							</div>

							<div className={styles.cases_block}>
								<h3 className={styles.block_title}>OUR CLIENTS</h3>
								<div className={styles.cases}>
									<div className={styles.case}>
										<Link to="success-stories/chemwatch-web" className={styles.case_name}>Chemwatch</Link>
										<p className={styles.case_info}>The World's leader in Chemical Safety Management.</p>
									</div>

									<div className={styles.case}>
										<Link to="success-stories/bp-one" className={styles.case_name}>BP One</Link>
										<p className={styles.case_info}>Mobile apps suite for multinational oil and gas company.</p>
									</div>
								</div>

								<div className={styles.cases}>
									<div className={styles.case}>
										<Link to="success-stories/ecocredit" className={styles.case_name}>EcoCredit</Link>
										<p className={styles.case_info}>CO2 credit marketplace.</p>
									</div>

									<div className={styles.case}>
										<Link to="success-stories/eat-better" className={styles.case_name}>I Eat Better</Link>
										<p className={styles.case_info}>Food diary mobile application..</p>
									</div>
								</div>

								<div className={styles.cases}>
									<div className={styles.case}>
										<Link to="success-stories/trakopolis" className={styles.case_name}>Trakopolis</Link>
										<p className={styles.case_info}>Real-time tracking and management of corporate assets..</p>
									</div>

									<div className={styles.case}>
										<Link to="success-stories/feaster" className={styles.case_name}>Feaster</Link>
										<p className={styles.case_info}>Mobile client for events organizing platform.</p>
									</div>
								</div>
							</div>
						</div>

						<p className={styles.rights}>©Techcore 2024. All rights reserved. <NavLinkScroll className={styles.link} to="/privacy-policy">Privacy Policy</NavLinkScroll></p>
					</div>
				</div>
			</div>

			{isContactUsModalVisible && (
				<Modal
					open={isContactUsModalVisible}
					className={styles.contact_us_modal}
					closable={false}
				>
					<div className={styles.content}>
						<Button onClick={() => setIsContactUsModalVisible(false)} className={`${styles.hamburger_button} ${styles.close_icon_button} ${isContactUsModalVisible && styles.collapsed}`}>
							<span className={styles.line}/>
						</Button>

						<ContactUs isForTopMenu={true} />
					</div>
				</Modal>
			)}
		</>
	)
}

export default Footer