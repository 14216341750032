import styles from "./contact-us.module.css"
import HubspotForm from "react-hubspot-form"

const ContactUs = ({ isForTopMenu = false }) => {
    return (
        <div className={styles.contact_us_block}>
            <div className="container">
                <div className={styles.content}>
                    <div className={styles.info}>
                        <h3>Ready to scale your team?</h3>

                        <p><a href="https://outlook.office365.com/owa/calendar/meet@techcore-group.com/bookings/" target="_blank" rel="noreferrer">Book a call</a> or fill out the form below and we’ll get back to you once we’ve processed your request.</p>

                        <p>You can also send us your request to</p>

                        <p><span>contact@techcore-group.com</span></p>
                    </div>

                    <div className={styles.form_block}>
                        <HubspotForm
                            region="eu1"
                            portalId="143870587"
                            formId="d429c5e4-75cd-47bf-9e35-d6b43c2d376e"
                            cssClass="hubspot-form"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs