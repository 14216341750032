import CaseButton from "./case-button/case-button"
import styles from "./case-switcher.module.css"

const CaseSwitcher = ({ selectedType, onFilterChange }) => {
    const handleSortCases = (type) => {
        onFilterChange(type)
    }

    return (
        <div className={styles.case_switcher}>
            <CaseButton onClick={() => handleSortCases(false)} isActive={!selectedType}>All</CaseButton>
            <CaseButton onClick={() => handleSortCases("chemical")} isActive={selectedType === "chemical"}>Chemical</CaseButton>
            <CaseButton onClick={() => handleSortCases("oil-gas")} isActive={selectedType === "oil-gas"}>Oil & Gas</CaseButton>
            <CaseButton onClick={() => handleSortCases("greentech")} isActive={selectedType === "greentech"}>GreenTech</CaseButton>
            <CaseButton onClick={() => handleSortCases("inventory-management")} isActive={selectedType === "inventory-management"}>Inventory Management</CaseButton>
            <CaseButton onClick={() => handleSortCases("finance-insurance")} isActive={selectedType === "finance-insurance"}>Finance & Insurance</CaseButton>
            <CaseButton onClick={() => handleSortCases("foodtech")} isActive={selectedType === "foodtech"}>FoodTech</CaseButton>
            <CaseButton onClick={() => handleSortCases("social")} isActive={selectedType === "social"}>Social</CaseButton>
            <CaseButton onClick={() => handleSortCases("retail-ecommerce")} isActive={selectedType === "retail-ecommerce"}>Retail & Ecommerce</CaseButton>
        </div>
    )
}

export default CaseSwitcher