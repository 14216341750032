import ContactUs from "../shared/contact-us/contact-us"
import Footer from "../shared/footer/footer"
import styles from "./industries.module.css"
import IndustryServices from "../shared/industry-services/industry-services"

const Industries = () => {
    return (
        <div className={styles.industries_page}>
            <IndustryServices
                title="Industries"
                casesColumnsInfo={[
                    { cases: [ "Chemical", "Oil & Gas", "Transportation & Logistics", "Business", "GreenTech" ] },
                    { cases: [ "Inventory Management", "Fitness & Sport", "Healthcare", "Finance & Insurance", "FoodTech" ] },
                    { cases: [ "Social", "Gaming", "Retail & Ecommerce", "eLearning" ] }
                ]}
            />

            <ContactUs />
        </div>
    )
}

export default Industries