import { Button } from "antd"
import styles from "./case-button.module.css"

const CaseButton = ({ isActive, onClick, children }) => {
    return (
        <Button
            onClick={onClick}
            className={`${styles.case_button} ${isActive ? styles.active : ""}`}
        >
            {children}
        </Button>
    )
}

export default CaseButton