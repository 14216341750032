import { Button } from "antd"
import styles from "./what-is-next-block.module.css"

const WhatIsNextBlock = () => {
    return (
        <div className={styles.what_is_next_block}>
            <div className="container">
                <h3 className={styles.title}>What is next</h3>

                <div className={styles.steps_block}>
                    <div className={styles.step}>
                        <div className={styles.dash}></div>

                        <div className={styles.content}>
                            <div className={styles.number_block}>
                                <p>1 STEP</p>
                            </div>

                            <p className={styles.title}>Join exploration call</p>

                            <p className={styles.info}>Tell us more about your business on a discovery call. We’ll discuss team structure and approach, success criteria, timescale, budget, and required skill sets to see how we can help.</p>
                        </div>
                    </div>

                    <div className={styles.step}>
                        <div className={styles.dash}></div>

                        <div className={styles.content}>
                            <div className={styles.number_block}>
                                <p>2 STEP</p>
                            </div>

                            <p className={styles.title}>Discuss solution and team structure</p>

                            <p className={styles.info}>In a matter of days, we will finalize your project specifications, agree on an engagement model, select and onboard your team.</p>
                        </div>
                    </div>

                    <div className={styles.step}>
                        <div className={styles.dash}></div>

                        <div className={styles.content}>
                            <div className={styles.number_block}>
                                <p>3 STEP</p>
                            </div>

                            <p className={styles.title}>Get started and track performance</p>

                            <p className={styles.info}>Once we’ve agreed on milestones, we’ll immediately get to work. We’ll track progress, report updates, and continuously adapt to your needs.</p>
                        </div>
                    </div>

                    <div className={styles.button_block}>
                        <div className={styles.dash}></div>

                        <a
                            href="https://outlook.office365.com/book/meet@techcore-group.com/"
                            target="_blank"
                            rel="noreferrer"
                            className={styles.button}
                        >
                            schedule a call
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatIsNextBlock