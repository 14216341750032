import CasesColumn from "./cases-column/cases-column"
import styles from "./technologies-block.module.css"

const TechnologiesBlock = () => {
    return (
        <div className={styles.technologies_block}>
            <div className="container">
                <h3 className={styles.title}>Technologies and Platforms we use</h3>

                <div className={styles.cases_block}>
                    <CasesColumn
                        title="Front-end"
                        cases={["React.js", "Angular", "Vue.js", "Nest.js", "ASP.NET Core"]}
                    />

                    <CasesColumn
                        title="Back-end"
                        cases={[".NET", "PHP", "Python", "Node.js"]}
                    />

                    <CasesColumn
                        title="Mobile"
                        cases={["Android", "iOS", "Flutter", "Xamarin", "Xamarin.Forms", ".NET MAUI"]}
                    />

                    <CasesColumn
                        title="Cloud"
                        cases={["AWS", "MS Azure", "Google Cloud"]}
                    />

                    <CasesColumn
                        title="Data"
                        cases={["Oracle", "MS SQL", "PostgreSQL", "DynamoDb", "MongoDb", "AWS Redshift", "Apache AirFlow", "Apache Kafka"]}
                    />
                </div>
            </div>
        </div>
    )
}

export default TechnologiesBlock