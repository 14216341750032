import { Button } from "antd"
import styles from "./main-block.module.css"

const MainBlock = () => {
    const onMoreButtonClick = () => {
        const element = document.getElementById("numbers_block");

        if (element) {
            const topMenu = document.getElementById("top_menu");
            const topMenuRect = topMenu?.getBoundingClientRect();
            const topMenuHeight = topMenuRect?.height;
            const elementRect = element.getBoundingClientRect();
            
            window.scrollBy({ top: elementRect.y - topMenuHeight, left: 0, behavior: "smooth" })
        }
    }

    return (
        <div className={styles.main_block}>
            <div className="container">
                <h1 className={styles.title}>Accelerate your Roadmap with <span>Top-tier Developers</span></h1>

                <p className={styles.description}>Scaling your business depends on your capability to find, onboard, and retain exceptional developers. The process of attracting, interviewing, assessing, hiring, and navigating HR challenges can be complicated and time consuming. Techcore streamlines this entire process for you. We provide developers who have been rigorously evaluated, employed, and prepared to integrate into your team on a full-time, long-term basis immediately.</p>

                <Button onClick={() => onMoreButtonClick()} className={styles.discover_more_button}>
                    <img src="/img/mouse-icon.svg" alt="mouse-icon" />
                    discover more
                </Button>
            </div>
        </div>
    )
}

export default MainBlock