import {Link} from "react-router-dom";
import styles from "./main-page-case.module.css";
import {ReactComponent as QuotesIcon} from "../../../../../shared/img/quotes.svg";

const MainPageCase = ({data}) => {
    if (!data) return ""

    const {
        backgroundColor = "linear-gradient(129.55deg, #004FD5 -8.39%, #003082 82.91%)",
        infoText,
        caseName,
        cases,
        imgPath,
        imgPosition = "center",
        link,
        feedback = null,
        services
    } = data || {}

    return (
        <Link
            to={`success-stories/${link}`}
            className={`${styles.case_block} ${styles[link]} ${services === "web" ? styles.web : styles.mobile}`}
            style={{backgroundImage: backgroundColor}}
        >
            <div>
                <div className={styles.wrapper}>
                    <div className={styles.info_block}>
                        <p className={styles.info}>{infoText}</p>
                        <h3 className={styles.case_name}>{caseName}</h3>

                        <div className={styles.cases_block}>
                            {cases?.map(item => {
                                return (
                                    <div key={item.text2} className={styles.case}>
                                        <div className={styles.title_block}>
                                            <p>{item.amount}</p>
                                            <p>{item.text1 || ""}</p>
                                        </div>

                                        <p className={styles.case_info}>{item.text2}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className={`${styles.img_block} ${styles[imgPosition]}`}>
                        <img src={Array.isArray(imgPath) ? imgPath[0] : imgPath} alt="illustration"/>
                    </div>

                </div>
                {feedback && (
                    <div className={styles.feedback}>
                        <QuotesIcon/>
                        <p>{feedback}</p>
                        <QuotesIcon/>
                    </div>
                )}
            </div>
        </Link>
    )
}

export default MainPageCase