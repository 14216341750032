import styles from "./numbers-block.module.css"

const NumbersBlock = () => {
    return (
        <div id="numbers_block" className={styles.numbers_block}>
            <div className={styles.numbers_case}>
                <h3>2012</h3>

                <p>company founded</p>
            </div>

            <div className={styles.numbers_case}>
                <h3>30+</h3>

                <p>talents in our team</p>
            </div>

            <div className={styles.numbers_case}>
                <h3>50+</h3>

                <p>successful projects</p>
            </div>

            <div className={styles.numbers_case}>
                <h3>10</h3>

                <p>countries on delivery map</p>
            </div>
        </div>
    )
}

export default NumbersBlock