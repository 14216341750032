export const casesInfo = Object.freeze({
    "chemical": [
        {
            order: 1,
            backgroundColor: "linear-gradient(116.73deg, #293771 0%, #1D2245 86.96%)",
            imgPath: "/img/main-page-case-illustration-chemwatch.webp",
            titleImgPath: "/img/info-illustration-chemwatch.png",
            caseName: "Chemwatch",
            infoText: "The World's leader in Chemical Safety Management",
            cases: [
                {amount: 11, text1: "years", text2: "cooperation"},
                {amount: 23, text2: "engineers team"},
                {amount: 6, text1: "clients", text2: "of the top 20 Fortune 500"}
            ],
            project: "Web Portal",
            services: "web",
            link: "chemwatch-web",
            solutions: [
                "Web app",
                "QA",
                "Security audit",
                "Product Design",
                "Oracle",
                "ASP.NET Core",
                "ASP.NET Web API"
            ],
            industry: 'Chemical',
            about: "Chemwatch is a leading provider of comprehensive chemical management software, offering access to the world’s largest database of chemicals. Their products are trusted by over 7,000 chemical manufacturers, users and suppliers worldwide, including 6 of the top 20 Fortune 500 companies.",
            deliverables_first: "Chemwatch was seeking a long-term partner with exceptional technical expertise in web and mobile app development, quality assurance processes. The primary objective was to revamp their outdated solution, enhance its scalability, and elevate the user interface and experience to a new level. The project required a comprehensive approach that addressed various technical and design challenges. The goal was to create a modern, efficient, and extensible solution that could meet the needs of Chemwatch’s clients and stakeholders.",
            deliverables_second: "Our team developed a comprehensive web portal that includes a variety of modules, such as chemical registration and management, Safety Data Sheet management, risk assessments management, approvals management, inventory management, and chemical labelling. The web portal’s interface supports 47 languages.",
        },

        {
            order: 9,
            backgroundColor: "linear-gradient(117deg, #1D4526 0%, #297153 86.96%)",
            imgPath: [
                "/img/case-illustration-chemwatch-mobile.webp",
                "/img/info-illustration-chemwatch-mobile.png"
            ],
            titleImgPath: "/img/info-illustration-chemwatch-mobile.webp",
            caseName: "Chemwatch Smarter Suite",
            infoText: "Chemicals Management and asset management app",
            cases: [
                {amount: 11, text1: "years", text2: "cooperation"},
                {amount: 4, text2: "mobile dev team"},
                {amount: 6, text1: "clients", text2: "of the top 20 Fortune 500"}
            ],
            project: "Cross-platform mobile application (Smart Suite)",
            services: "mobile",
            link: "chemwatch-mobile",
            solutions: [
                "Mobile app",
                "Product design",
                "Xamarin",
                "Xamarin.Forms",
            ],
            industry: 'Chemical',
            about: "With the Smarter Suite mobile app, customers can access extensive health and safety information, including risk assessment, and manage their chemical inventory from one location.",
            deliverables_first: "The goal of the Smarter Suite project was to develop a cross-platform mobile application that would replicate the functionality of their web portal with full support for both mobile devices and tablets. Our team successfully released the initial version of the Smarter Suite mobile app. Since then, we have continued to work on the project, adding new features that correspond to the full range of functionality available on the web portal.",
            deliverables_second: "",
        },

        {
            order: 11,
            backgroundColor: " linear-gradient(125deg, #9BB66D 0%, #3FA581 99.05%)",
            imgPath: '/img/case-illustration-bee-hive.png',
            titleImgPath: "/img/info-illustration-bee-hive.png",
            caseName: "BeeHive by Chemwatch",
            infoText: "THE WORLD'S LEADER IN CHEMICAL SAFETY MANAGEMENT",
            cases: [
                {amount: 11, text1: "years", text2: "cooperation"},
                {amount: 25, text2: "ENGINEERing team"},
                {amount: 6, text1: "clients", text2: "of the top 20 Fortune 500"}
            ],
            project: "Web Portal",
            services: "web",
            link: "beehive",
            solutions: [
                "Web app",
                "Product design",
                "AWS",
                "Node.js",
                "React.js",
                "PostgreSQL",
                "Amazon Aurora",
            ],
            industry: "Chemical",
            about: "Chemwatch is a leading provider of comprehensive chemical management software, offering access to the world’s largest database of chemicals. Their products are trusted by over 7,000 chemical manufacturers, users and suppliers worldwide, including 6 of the top 20 Fortune 500 companies.",
            deliverables_first: "Beehive’s mission was to help Chemwatch upgrade their existing solution, enhance its scalability, migrate it to a serverless architecture on AWS stack, reduce costs of database licences. The project required a comprehensive approach to address various technical challenges, such as data management and migration, microservices architecture design, and scalability.",
            deliverables_second: "By utilizing a serverless architecture, we were able to achieve high scalability, cost-effectiveness, and reliability. The web portal’s interface was designed with a user-centric approach, supporting 47 languages. The solution was rigorously tested through automated unit tests, integration tests, and end-to-end tests, ensuring high quality and minimizing the risk of errors. The result was a comprehensive, modern, and efficient solution that met Chemwatch’s needs and exceeded their expectations.",
        },
    ],

    "finance-insurance": [
        {
            order: 3,
            backgroundColor: "linear-gradient(122.07deg, rgb(0, 122, 254) -0.26%, rgb(7, 23, 160) 105.25%)",
            imgPath: "/img/case-illustration-pagos.png",
            titleImgPath: "/img/case-illustration-pagos.png",
            caseName: "Pagos",
            infoText: "Intelligent payment infrastructure for commerce",
            cases: [
                {amount: "1B+", text2: "transactions tracked"},
                {amount: 25, text2: "engineering team"},
                {amount: "15+", text2: "PSPs integrated"}
            ],
            project: "Web Portal",
            services: "web",
            link: "pagos-web",
            solutions: [
                "Web app",
                "QA",
                "Security audit",
                "Product Design",
                "Oracle",
                "AWS",
                "Amazon Redshift",
                "Microservices",
                "Node.js",
                "React.js",
                "eCharts.js",
            ],
            industry: 'Finance & Insurance',
            about: "Pagos Solutions is a startup in the field of payment systems infrastructure. The company is building a data platform and API-based microservices that can be integrated with any payment stack. The ultimate goal is to improve performance and optimize the existing business payment infrastructure.",
            deliverables_first: "Our goal was to create a web solution that would help Pagos Solutions' clients to export their payments data from any payment processor and analyze it. The solution needed to provide immediate payment data visualizations, automatic notifications on payment trends or problems.",
            deliverables_second: "Our team developed a SaaS platform that enables clients to consolidate and control all their payment data, create customized reports and dashboards, monitor trends. The solution includes best-in-class data visualizations, and allows clients to compare their performance to that of peers and benchmark vendor performance. By analyzing key metrics such as authorization rate, decline reasons, chargebacks/disputes, processing costs, interchange fees, and assessments, our solution helps clients optimize their checkout flow, reduce churn, increase approval rates, and manage risk.",
            feedback: "Throughout our five-year collaboration, the TechCore team has been instrumental in advancing our fintech vision. Their technical expertise and collaborative approach consistently exceed expectations, providing valuable contributions to our products. We continue to rely on the TechCore team as a steadfast and innovative partner in shaping the evolution of fintech. We know that with the TechCore team we can make fintech even cooler!",
        },

        {
            order: 13,
            backgroundColor: "linear-gradient(118.8deg, #0098D9 -15.13%, #06AD74 98.55%), linear-gradient(120.42deg, #37C1FD -36.6%, #33D09A 73.72%)",
            imgPath: [
                "/img/case-illustration-pagos-mobile.webp",
                "/img/info-illustration-pagos-mobile.png"
            ],
            titleImgPath: "/img/info-illustration-pagos-mobile.webp",
            caseName: "Pagos",
            infoText: "Intelligent payment infrastructure for commerce",
            cases: [
                {amount: "1B+", text2: "transactions tracked"},
                {amount: 25, text2: "engineering team"},
                {amount: "15+", text2: "PSPs integrated"}
            ],
            project: "Cross-platform mobile application (Smart Suite)",
            services: "mobile",
            link: "pagos-mobile",
            solutions: [
                "Mobile app",
                "Product design",
                "Flutter",
            ],
            industry: 'Finance & Insurance',
            about: "Pagos is a startup in the field of payment systems infrastructure. The company is building a data platform and API-based microservices that can be integrated with any payment stack.",
            deliverables_first: "The final deliverables of this project included a fully functional cross-platform mobile application built using Flutter, which replicated the web application’s payment data visualization and notification features. Additionally, the application was designed to be intuitive and user-friendly, with a clean and modern interface that aligned with Pagos' branding. Overall, our collaboration with Pagos product team resulted in a successful project that met all of the company’s goals and delivered a high-quality mobile application to their customers.",
            deliverables_second: "",
        },

        {
            order: 12,
            backgroundColor: "linear-gradient(243deg, #ADC8E8 16.67%, #549AC1 100%)",
            imgPath: '/img/case-illustration-m-stable.png',
            titleImgPath: "/img/info-illustration-m-stable.png",
            caseName: "mStable",
            infoText: "web-dashboard for in-depth stablecoin analytics",
            cases: [
                {amount: 16, text2: "metrics tracked"},
                {amount: 4, text1: "months", text2: "to release"},
            ],
            project: "Web Portal",
            services: "web",
            link: "mstable",
            solutions: [
                "Web app",
                "Product design",
                "AWS",
                "Node.js",
                "Vue.js",
                "Python",
            ],
            industry: "Finance & Insurance",
            about: "mStable is a stablecoin designed for decentralized finance, providing stability and liquidity to the DeFi ecosystem.",
            deliverables_first: "The goal of the mStable project is to develop a user-friendly analytics dashboard for mStable stablecoin family that provides accurate and up-to-date information on stablecoin total value locked (TVL) and enables informed decision-making for users.",
            deliverables_second: "The team has successfully released the functionality of the dashboard in a short period of four months. The dashboard includes features such as tracking stablecoin TVL, analyzing metrics, and monitoring trends, providing a comprehensive overview of the stablecoin market for users.",
        },
    ],

    "greentech": [
        {
            order: 4,
            backgroundColor: "linear-gradient(116.55deg, #32D285 4.54%, #019A50 86.96%)",
            imgPath: '/img/main-page-case-illustration-eco-credit.webp',
            titleImgPath: "/img/info-illustration-eco-credit.png",
            caseName: "EcoCredit",
            infoText: "CO2 Credit MARKETPLACE",
            cases: [
                {amount: 4, text1: "months", text2: "to  MVP"},
                {amount: 4, text2: "engineering team"}
            ],
            project: "Marketplace",
            services: "web",
            link: "ecocredit",
            solutions: [
                "Web app",
                "Product design",
                "AWS",
                "Node.js",
                "React.js",
            ],
            industry: 'GreenTech',
            about: "The EcoCredit marketplace is a platform that connects businesses with carbon offset projects to reduce global greenhouse gas emissions, which are mostly caused by businesses. This platform facilitates the purchase and sale of carbon offsets, which are used to compensate for carbon emissions. By participating in this marketplace, businesses can help reduce their carbon footprint and contribute to a more sustainable future.",
            deliverables_first: "The primary goal of the project was to deliver the MVP within the next four months. Our team successfully developed and delivered the MVP within the given timeframe. We created a user-friendly interface that made it easy for businesses to participate in the marketplace. We integrated various features such as project profiles, carbon credits, and a carbon calculator to help businesses better understand their carbon footprint. We hope this solution helps businesses take a step towards a more sustainable future by reducing their carbon footprint.",
            deliverables_second: "",
        },
    ],

    "retail-ecommerce": [
        {
            order: 8,
            backgroundColor: "linear-gradient(119deg, #0098D9 -15.13%, #6FD6D6 98.55%)",
            imgPath: '/img/main-page-case-illustration-boommy.webp',
            titleImgPath: "/img/main-page-case-illustration-boommy.webp",
            caseName: "Boommy",
            infoText: "FASHION MARKETPLACE PLATFORM",
            cases: [
                {amount: "50k+", text2: "registered users"},
                {amount: 6, text1: "months", text2: "to MVP"}
            ],
            project: "Marketplace",
            services: "web",
            link: "boommy",
            solutions: [
                "Web app",
                "QA",
                "ASP.NET MVC",
                "MS SQL",
                "jQuery",
            ],
            industry: 'Retail & Ecommerce',
            about: "Boommy is an e-commerce marketplace designed for trendy young people. It combines a social network and a platform for sharing things. Each user has the opportunity to find and buy things they like, exchange clothes, sell boring or unsuitable wardrobe items. In addition to perfection, here you can chat with friends, chat with stylists, meet interesting and fashionable people.",
            deliverables_first: "Our main goal was to create an ecosystem that provided convenience and interest for stylists, fashion bloggers, designers, clothing sellers, and other participants in the fashion industry. To achieve this, our team developed a user-friendly interface that allowed for easy navigation and social interaction among users.",
            deliverables_second: " Our deliverables included the development and launch of the Boommy platform. We ensured that it was responsive and optimized for various devices, making it accessible to a wider range of users. Additionally, we integrated various features such as a messaging system, search filters, and secure payment gateways, to enhance the user experience.",
        },
    ],

    "inventory-management": [
        {
            order: 6,
            backgroundColor: "linear-gradient(120.12deg, rgb(117, 99, 98) 0%, rgb(32, 36, 63) 112.92%)",
            imgPath: '/img/case-illustration-trakopolis.webp',
            titleImgPath: "/img/info-illustration-trakopolis.png",
            caseName: "Trakopolis",
            infoText: "REAL-TIME TRACKING AND MANAGEMENT OF CORPORATE ASSETS",
            cases: [
                {amount: "1,3k", text2: "clients"},
                {amount: "100k+", text2: "events tracked everyday"},
                {amount: "10+", text2: "integrations with sensors"}
            ],
            project: "Web Portal",
            services: "web",
            link: "trakopolis",
            solutions: [
                "Web app",
                "Mobile app",
                "QA",
                "Security audit",
            ],
            industry: "Inventory Management",
            about: "Trakopolis is a cloud-based platform that provides solutions for real-time tracking, data analysis and management of corporate assets such as equipment, devices, vehicles and workers.",
            deliverables_first: "Our goal was to create a robust and scalable platform that could handle large volumes of data and provide actionable insights in real-time. To achieve this, we designed and implemented a complex architecture that included data ingestion, storage, and analysis using advanced algorithms. We also integrated various sensors and devices into the platform, allowing for real-time monitoring and tracking of assets.",
            deliverables_second: "Our deliverables included a fully functional cloud-based platform that allowed businesses to track and manage their assets in real-time. The platform included a customizable dashboard, advanced reporting capabilities, and real-time alerts and notifications. We also provided ongoing support and maintenance services to ensure the platform remained up-to-date and functional.",
        },
    ],

    "foodtech": [
        {
            order: 10,
            backgroundColor: "linear-gradient(117deg, #9854A7 0%, #E3A2F4 86.96%)",
            imgPath: [
                "/img/case-illustration-fast-and-yammy-mobile.webp",
                "/img/info-illustration-fast-and-yammy-mobile.png",
            ],
            titleImgPath: "/img/info-illustration-fast-and-yammy-mobile.webp",
            caseName: "Fast & Yammy",
            infoText: "FOOD DELIVERY MOBILE APP",
            cases: [
                {amount: 4, text2: "months"},
                {amount: "92%", text2: "code sharing iOS/Android"},
            ],
            project: "Cross-platform mobile application (Smart Suite)",
            services: "mobile",
            link: "fast-yammy",
            solutions: [
                "Mobile app",
                "Flutter",
            ],
            industry: 'FoodTech',
            about: "Fast & Yammy is a new food delivery platform startup that offers a wide selection of restaurants for users to order from, with a focus on delivering high-quality and unique dining experiences.",
            deliverables_first: "Our primary goal for this project was to create a user-friendly food delivery app that was intuitive and easy to use, with a modern interface that aligned with current design trends.",
            deliverables_second: "Our deliverables included a fully functional food delivery mobile application built using Flutter framework that offered a range of features, such as the ability to browse restaurants and menus, select dishes, and track orders in real-time. Additionally, the app was integrated with third-party payment systems, making it easy for users to pay for their orders securely.",
        },

        {
            order: 5,
            backgroundColor: "linear-gradient(122.07deg, #007AFE -0.26%, #0717A0 105.25%), linear-gradient(116.55deg, #37C1FD 4.54%, #3786FD 75.67%)",
            imgPath: [
                "/img/case-illustration-i-eat-better-mobile.webp",
                "/img/info-illustration-i-eat-better-mobile.png",
            ],
            titleImgPath: "/img/info-illustration-i-eat-better-mobile.webp",
            caseName: "I Eat Better",
            infoText: "FOOD DIARY MOBILE APPLICATION",
            cases: [
                {amount: "5M+", text2: "installs"},
                {amount: 5, text1: "months", text2: "to market"},
                {amount: "92%", text2: "code sharing iOS/Android"}
            ],
            project: "Cross-platform mobile application (Smart Suite)",
            services: "mobile",
            link: "eat-better",
            solutions: [
                "Mobile app",
                "UX/UI design",
                "Xamarin",
                "Xamarin.Forms",
            ],
            industry: 'FoodTech',
            about: "IEatBetter is a mobile app that enables users to monitor their daily food intake and make healthier eating choices. The app comes with a built-in database containing thousands of food items, making it easy for users to add their customized meals. The primary objective of the app is to streamline diet tracking, while promoting healthier eating habits.",
            deliverables_first: "The primary goal was to rewrite the existing client app, which was not extendable due to spaghetti code and had performance issues in the app’s database. By using Xamarin.Forms, we aimed to create a shared codebase for both iOS and Android platforms, reduce development costs, and simplify maintenance and support.",
            deliverables_second: "As a result, our team released a fully functional mobile app with a user-friendly interface and an efficient design, with an impressive percentage of codebase sharing — 92%. The app’s enhanced performance makes it more responsive and reliable, improving the user experience.",
        },

    ],

    "oil-gas": [
        {
            order: 7,
            backgroundColor: "linear-gradient(118deg, #003082 0%, #4A7CD1 100%)",
            imgPath: [
                "/img/case-illustration-bp-one-mobile.webp",
                "/img/info-illustration-bp-one-mobile.png",
            ],
            titleImgPath: "/img/info-illustration-bp-one-mobile.webp",
            caseName: "BP One",
            infoText: "MOBILE APPS SUITE FOR MULTINATIONAL OIL AND GAS COMPANY",
            cases: [
                {amount: 2, text1: "years", text2: "to revamp 7 apps"},
                {amount: 7, text1: "apps", text2: "created"},
                {amount: "94%", text2: "code sharing iOS/Android"},
            ],
            project: "Cross-platform mobile application (Smart Suite)",
            services: "mobile",
            link: "bp-one",
            solutions: [
                "Mobile app",
                "UX/UI design",
                "Xamarin.Forms",
            ],
            industry: 'Oil & Gas',
            about: "Prior to working with our team, the client faced challenges related to their multiple mobile applications built on outdated technology. Supporting these apps was difficult due to a lack of specialists, and adding changes and releasing updates took a significant amount of time. These challenges consumed valuable time and resources and hindered the client’s ability to effectively maintain and improve their mobile applications.",
            deliverables_first: "To address these issues, we worked with the client to rewrite over 7 outdated mobile applications and integrated them into a single all-in-one mobile app named BP One, using Xamarin.Forms. This streamlined the support process, reducing complexity and the amount of time and resources required. The updated mobile app significantly improved the update delivery time, enabling the client to release new features and bug fixes more quickly. Overall, the integration of multiple apps into one all-in-one mobile app resulted in a more efficient and effective mobile app experience for end users.",
            deliverables_second: "",
        },
    ],

    "social": [
        {
            order: 2,
            backgroundColor: "linear-gradient(116.73deg, #9F2FFF 0%, #5700A2 73.63%)",
            imgPath: "/img/case-illustration-feaster-mobile.webp",
            titleImgPath: "/img/info-illustration-feaster-mobile.webp",
            caseName: "Feaster",
            infoText: "MOBILE CLIENT FOR EVENTS ORGANIZING PLATFORM",
            cases: [
                {amount: 3, text1: "months", text2: "to MVP"},
                {amount: "94%", text2: "code sharing iOS/Android"}
            ],
            project: "Cross-platform mobile application (Smart Suite)",
            services: "mobile",
            link: "feaster",
            solutions: [
                "Mobile app",
                "Xamarin.Forms",
            ],
            industry: 'Social',
            about: "Feaster is a startup that simplifies event planning for users. The platform offers a range of features, including budget management, venue selection, and the ability to hire professional services such as hosts, DJs, singers, and animators. With Feaster, users can effortlessly invite guests through social networks or their contact lists. As a startup, Feaster aims to revolutionize the event planning industry by offering an easy-to-use, comprehensive platform for users to plan their events.",
            deliverables_first: "Our primary goal for the Feaster project was to develop a cross-platform mobile app within a tight timeframe due to the startup’s need to bring the product to market as soon as possible.",
            deliverables_second: "Despite the tight deadlines, our team successfully released a fully functional mobile app for both iOS and Android platforms within five months of the project’s inception, achieving an impressive codebase sharing percentage of 94%",
        },
    ],

})