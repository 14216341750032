import styles from "./card.module.css"

const Card = ({ title, children }) => {
    return (
        <div className={styles.card}>
            {children}

            <p className={styles.title}>{title}</p>
        </div>
    )
}

export default Card